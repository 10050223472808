import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();
$(document).ready(function(){
  $('.sim-thumb').on('click', function() {
    $('#main-product-image').attr('src', $(this).data('image'));
    $('.sim-thumb').removeClass('active');
    $(this).addClass('active');
  })
  $('#search').on('click', function(){
    $('header .search').addClass('active');
    $('#search-input').focus();
    $('#search-input').focusout(function(){
      $("header .search").removeClass('active');
    });
  })
  $('#search2').on('click', function(){
    $('footer .search').addClass('active');
    $('#search-input2').focus();
    $('#search-input2').focusout(function(){
      $("footer .search").removeClass('active');
    });
  })
  $('.floor').on('click',function(){
    $('.floor').removeClass('active');
    $(this).addClass('active');
    let tabnum = $(this).data("tab");
    $("a[href='#panel"+tabnum+"c']").click();
  })
  $('.tabs-title>a').on('click',function(){
    $('.floor').removeClass('active');
    let tabnum = $(this).data("tab");
    $('.floor_'+tabnum).addClass('active');
  })

  $('.floor__room').on('mouseover', function(){
    let num = $(this).data('elem');
    $('.shop-point:nth-child('+num+')').addClass('hover');
  }).on('mouseout',function(){
    $('.shop-point').removeClass('hover');
  });
  $('.shop-point').on('mouseover', function(){
    let num = $(this).index() + 1;
    console.log(num);
    $('.floor__room[data-elem="'+num+'"]').addClass('hover');
  }).on('mouseout',function(){
    $('.floor__room').removeClass('hover');
  });
  $('a[href="#panel5c"]').click();
})
